"use client";
import { useEffect, useState } from "react";
import Spinner from "../components/ui/spinner";
import { RxDoubleArrowRight } from "react-icons/rx";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../routes";
import { setActiveOrganizationId } from "../store/slices/account.slice";

export default function AppInitializerView() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<string>("");
    const dispatch = useDispatch();

    useEffect(() => {
        // if (authenticated) {
        //     navigate(ROUTES.PERSONAS);
        // }
        const activeOrg = searchParams.get("vendor");
        if (!!activeOrg && typeof activeOrg === "string") {
            window.localStorage.setItem("active_org", activeOrg);
            // Set active organization in state
            dispatch(setActiveOrganizationId(activeOrg));
            // Navigate to other UI
            navigate(ROUTES.PERSONAS);
        } else {
            // TODO: SHOW ERROR WITH LOADING
            setError("The link you provided is invalid or you do not have access to access to your commercial account's Life Lab.");
        }
    }, [dispatch, navigate, searchParams]);

    return (
        <main className="min-h-screen flex flex-col items-center pt-80 min-w-screen px-10 ">
            <div className="row flex items-center bg-transparent">
                <div className="location bg-transparent">
                    <img src={"https://assets.neoworlder.com/img/neoworlder_logo_black.png"} alt={"@NeoWorlder"} className="dark:hidden rounded-[4px] w-auto h-[55px]" />
                    <img src={"https://assets.neoworlder.com/img/neoworlder_logo_white.png"} alt={"@NeoWorlder"} className="hidden dark:block rounded-[4px]  w-auto h-[55px]" />
                </div>
                <div className="arrow flex items-center mr-[12px]">
                    <RxDoubleArrowRight size={"30px"} />
                </div>
                <div className="destination">
                    <img src={"https://assets.neoworlder.com/img/v1/lifelab_logo_black.png"} alt={"@NeoWorlder"} className="dark:hidden rounded-[4px] w-auto h-[40px]" />
                    <img src={"https://assets.neoworlder.com/img/v1/lifelab_logo_white.png"} alt={"@NeoWorlder"} className="hidden dark:block rounded-[4px]  w-auto h-[40px]" />
                </div>
            </div>
            <div className="mt-4">
                <p className="text-base text-center text-neutral-700">You are leaving from NeoWorlder into your Organization's Lab</p>
            </div>
            <div className="mt-3">
                {error ? (
                    <div className="rounded p-3 max-w-lg bg-red-50 text-red-500 border border-red-500">
                        <h4 className="font-semibold">Something went wrong</h4>
                        <p>{error}</p>
                    </div>
                ) : (
                    <div className="loader flex gap-2 justify-normal items-center text-muted-foreground">
                        <Spinner />
                        <p>Redirecting you from NeoWorlder to your commercial account's Life Lab.</p>
                    </div>
                )}
            </div>
        </main>
    );
}
