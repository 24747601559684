import { ButtonGroup, Button } from "@chakra-ui/react";
import React, { Component, ReactNode } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { ROUTES } from "../routes";

type ErrorBoundaryProps = {
    children: ReactNode;
};

type ErrorBoundaryState = {
    hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.log(error, errorInfo);
    }

    navigatePage(url: string) {
        window.location.href = url;
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="h-screen w-screen relative bg-gray-50 flex flex-col justify-center items-center">
                    <img src="https://assets.neoworlder.com/img/v1/lifelab_logo_black.png" alt="" className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]" />
                    <RiErrorWarningFill className="text-red-600" size={"55px"} />
                    <h1 className="text-red-900 text-[22px] mt-[36px] mb-[8px] font-semibold">Something went wrong</h1>
                    <p className="mb-[10px] text-slate-500 max-w-[450px] text-center text-[15px]">We've encountered a problem on our end. Sorry for the inconvenience! Our team is already working on fixing the issue.</p>
                    <ul className="mb-[40px] text-center text-slate-500 max-w-[450px] text-[15px]">
                        <li className="text-slate-500 mb-1 max-w-[450px] text-[15px]">
                            <strong>Don't Panic:</strong> It's just a wrong turn in the vast internet universe.
                        </li>
                        <li className="text-slate-500 mb-1 max-w-[450px] text-[15px]">
                            <strong>Navigation Tips:</strong> Check the URL for typos.
                        </li>
                        <ul className="pl-4 text-center">
                            <li className="text-slate-500 max-w-[450px] text-[15px]">Use the navigation menu to find your way.</li>
                            <li className="text-slate-500 max-w-[450px] text-[15px]">Return to our Home Page.</li>
                            <li className="text-slate-500 max-w-[450px] text-[15px]">Need Assistance? If you're sure this page should exist, contact our Support Team for help.</li>
                        </ul>
                    </ul>
                    <ButtonGroup spacing={4}>
                        <Button onClick={() => this.navigatePage(ROUTES.PERSONAS)} variant="outline" size="sm">
                            Go to Home page
                        </Button>
                    </ButtonGroup>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
