import React from "react";
import { cn } from "../../utils";

interface INeoWorlderIcon {
    size: number;
    className?: string;
}

const NeoWorlderIcon: React.FC<INeoWorlderIcon> = ({ size, className }) => {
    return (
        <div style={{ height: size, width: size }} className={cn("overflow-hidden rounded", className)}>
            <img src="https://assets.neoworlder.com/img/neoworlder_icon_black.png" className="h-full w-full" alt="neoworlder_icon" />
        </div>
    );
};

export default NeoWorlderIcon;
